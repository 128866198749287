import { ApiGetOrders } from "@/server"
import { makeAutoObservable } from "mobx"

class ListStore {
  user?: {
    code: string
    idcard: string
    name: string
  }

  list: API.OrderItem[] | null = null

  loading = false

  constructor() {
    makeAutoObservable(this)
  }

  async fetchData(user: {
    code: string
    idcard: string
    name: string
  }) {
    if (this.user?.name === user.name && this.user.idcard === user.idcard && this.user.code === user.code) return
    this.user = user
    this.loading = true
    this.list = null
    try {
      const { data } = await ApiGetOrders({
        code: user.code,
        name: user.name,
        idcard: user.idcard
      })
      this.loading = false
  
      if (data.data) {
        this.list = data.data
      }

      return data.data ?? []
    } catch (e) {
      this.loading = false
      throw e
    }
  }
}

const listStore = new ListStore()

export default listStore
