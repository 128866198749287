import request from './request'

export function ApiGetCaptcha() {
  return request.get<API.baseResponse<API.Captcha>>('/paymentinfo/captcha')
}

export function ApiGetOrders(params: {
  code: string
  idcard: string
  name: string
}) {
  return request.get<API.baseResponse<API.OrderItem[]>>('/paymentinfo/orderList', {
    params
  })
}

export function ApiGetOrderDetail(params: {
  orderId: string
}) {
  return request.get<API.baseResponse<API.BillInfo>>('/paymentinfo/billList', {
    params
  })
}

export function ApiGetRemark() {
  return request.get<API.baseResponse<string>>('/paymentinfo/remark')
}