import { ApiGetOrderDetail, ApiGetRemark } from '@/server'
import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import { useRequest } from 'ahooks'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

const Detail: React.FC = () => {
  const params = useParams<{ id: string }>()
  const [open, setOpen] = useState(false)
  const history = useHistory()
  
  const service = useCallback(() => {
    return ApiGetOrderDetail({
      orderId: params.id
    })
  }, [params])
  
  const { data, loading } = useRequest(service)

  const { data: remarkData } = useRequest(ApiGetRemark)

  const name = useMemo(() => {
    return data?.data.data?.displayName
  }, [data?.data.data])

  return (
    <div>
      <AppBar>
        <Toolbar>
          <div style={{
            position: 'absolute',
            left: 20
          }}>
            <IconButton color="inherit" edge="start" onClick={() => history.goBack()}>
              <ArrowBackIos fontSize="small" />
            </IconButton>
          </div>

          <Typography style={{ width: '100%' }} align="center">账单列表</Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container>
        {
          loading ?
            <CircularProgress/> :
            <Box my={2}>
              <Typography
                variant="h5"
                style={{
                  marginBottom: 10
                }}
              >{name}</Typography>

              {
                data?.data.data?.list.map((item, index) => {
                  return (
                    <div key={`item_${index}`}>
                      <Card>
                        <CardContent>
                          <div>
                            <Grid container>
                              <Grid item xs={10}>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <Typography variant="h5">{ item.amount }</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant="h6">{ item.date }</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>{ item.period }</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>还款日</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="overline" color="error">未还</Typography>
                              </Grid>
                            </Grid>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  )
                })
              }

              <Button
                fullWidth
                color="primary"
                variant="contained"
                style={{
                  marginTop: 30
                }}
                size="large"
                onClick={() => setOpen(true)}
              >去还款</Button>
            </Box>
        }
      </Container>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll="paper"
        fullWidth
      >
        <DialogTitle>还款说明</DialogTitle>
        <DialogContent>
          {remarkData?.data.data}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">知道了</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Detail
