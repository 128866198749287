import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  CircularProgress,
  AppBar,
  Typography,
  Toolbar,
  Container,
  Box,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  IconButton,
  Button
} from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import userStore from '@/store/user'
import listStore from '@/store/list'
import { show } from '@/lib/toast'

const List: React.FC = () => {
  const history = useHistory()

  const directToDetail = useCallback((id: string) => {
    history.push(`/detail/${id}`)
  }, [history])

  const checkLength = useCallback(() => {
    if (listStore.list?.length === 0) {
      show('不存在还款中订单，请核对您的信息或咨询客服')
      history.replace('/login')
    }
  }, [history])

  useEffect(() => {
    console.log('effect', userStore.name, listStore.loading)
    if (userStore.name && listStore.loading === false) {
      listStore.fetchData({
        name: userStore.name,
        idcard: userStore.idcard ?? '',
        code: userStore.captcha ?? ''
      }).then(() => {
        checkLength()
      }).catch(() => {
        console.log('catch')
        history.replace('/login')
      })
    }
  }, [checkLength, history])

  useEffect(() => {
    if (userStore.name === undefined) {
      history.replace('/login')
    }
  }, [history])

  return (
    <div>
      <AppBar>
        <Toolbar>
          <div style={{
            position: 'absolute',
            left: 20
          }}>
            <IconButton color="inherit" edge="start" onClick={() => history.goBack()}>
              <ArrowBackIos fontSize="small" />
            </IconButton>
          </div>
          <div style={{ flex: 1 }}>
            <Typography align="center">订单列表</Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container>
        {
          !listStore.loading ?
            <Box my={2}>
              <Grid container spacing={2}>
              {
                listStore.list?.map(item =>
                  <Grid item xs={12} key={`order_${item.orderId}`}>
                    <Card>
                      <CardActionArea
                        onClickCapture={() => {
                          directToDetail(item.orderId)
                        }}
                      >
                        <CardContent>
                          <Grid container alignItems="flex-end" spacing={2}>
                            <Grid item>
                              <Typography variant="body1">{ item.displayProductName }</Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="textSecondary">{dayjs(Number(item.transferSuccessTime)).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={9}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography variant="h6" color="primary">{item.lendingAmounts}</Typography>
                                  <Typography variant="body2" color="textSecondary">金额(元)</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="h6" color="error">{item.informatifon}</Typography>
                                  <Typography variant="body2" color="textSecondary">期限</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={3}>
                              <Button size="small" variant="contained" color="primary">查看账单</Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )
              }
              </Grid>
          </Box> :
          <CircularProgress/>
        }
      </Container>
    </div>
  )
}

export default observer(List)
