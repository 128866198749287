import React, { useEffect } from 'react'
import {
  Container,
  TextField,
  Button,
  Grid,
  CircularProgress,
  AppBar,
  Toolbar,
  Typography
} from '@material-ui/core'
import { useRequest  } from 'ahooks'
import { ApiGetCaptcha } from '@/server'
import * as yup from 'yup'
import {
  useFormik
} from 'formik'
import userStore from '@/store/user'
import { useHistory } from 'react-router-dom'

const validationSchema = yup.object().shape({
  name: yup.string().required('请输入姓名').typeError('请输入姓名'),
  idcard: yup.string().required('请输入身份证').typeError('请输入身份证').matches(/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, '身份证号码格式不正确'),
  captcha: yup.string().required('请输入验证码').typeError('请输入验证码')
})

const ValidCode: React.FC = () => {
  const { data, loading, run, cancel } = useRequest(ApiGetCaptcha)
  
  useEffect(() => {
    return () => {
      cancel()
    }
  }, [cancel])

  if (loading) {
    return <CircularProgress/>
  }


  return (
    <div onClick={run} style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'flex-end' }}>
      <img alt="" src={data?.data.data?.image}/>
    </div>
  )
}

const Login: React.FC = () => {

  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      name: userStore.name || '',
      idcard: userStore.idcard || '',
      captcha: userStore.captcha || ''
    },
    validationSchema: validationSchema,
    onSubmit: (value) => {
      userStore.setUser(value)
      history.push('/list')
    }
  })

  return (
    <div>
      <AppBar>
        <Toolbar>
          <Typography style={{ width: '100%' }} align="center">账单查询</Typography>
        </Toolbar>
      </AppBar>
      <Toolbar/>
      <Container>
        <Typography style={{ marginTop: 20 }} variant="body2" color="textSecondary">请输入您的信息，用于查询还款中的订单</Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="姓名"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          fullWidth
          margin="normal"
          label="身份证号"
          name="idcard"
          value={formik.values.idcard}
          onChange={formik.handleChange}
          error={formik.touched.idcard && Boolean(formik.errors.idcard)}
          helperText={formik.touched.idcard && formik.errors.idcard}
        />
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              margin="normal"
              label="验证码"
              name="captcha"
              value={formik.values.captcha}
              onChange={formik.handleChange}
              error={formik.touched.captcha && Boolean(formik.errors.captcha)}
              helperText={formik.touched.captcha && formik.errors.captcha}
            />
          </Grid>
          <Grid item>
            <ValidCode/>
          </Grid>
        </Grid>
        <div style={{ marginTop: 20 }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >查询</Button>
        </div>
        </form>
      </Container>
    </div>
  )
}

export default Login