import { show } from '@/lib/toast'
import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://easy-api.hydbest.com'
})

instance.interceptors.request.use(function (config) {
  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  if (response.data.retCode !== 0 && response.data.retMessage) {
    show(response.data.retMessage)
    return Promise.reject(new Error(response.data.retMessage))
  }
  return response
}, function (error) {
  show('网络错误')
  return Promise.reject(error)
})

export default instance