import { Snackbar } from '@material-ui/core'
import React from 'react'
import ReactDOM from 'react-dom'

interface ToastProps {
  message: string
  onClose?: () => void
}

const Toast: React.FC<ToastProps> = (props) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open
      autoHideDuration={6000}
      message={props.message}
      onClose={props.onClose}
    />
  )
}


export function show(text: string) {
  const ToastDom = document.createElement('div')
  document.body.append(ToastDom)
  ReactDOM.render(
    <React.StrictMode>
      <Toast message={text} onClose={() => {
        setTimeout(() => {
          ToastDom.remove()
        }, 1000)
      }}/>
    </React.StrictMode>,
    ToastDom
  );
}