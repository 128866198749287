import { makeAutoObservable } from "mobx"

class UserStore {
  name?: string
  idcard?: string
  captcha?: string

  get info() {
    return {
      name: this.name,
      idcard: this.idcard,
      captcha: this.captcha
    }
  }

  constructor() {
    makeAutoObservable(this)
  }

  setUser(payload: {
    name?: string
    idcard?: string
    captcha?: string
  }) {
    this.name = payload.name
    this.idcard = payload.idcard
    this.captcha = payload.captcha

    localStorage.setItem('user', JSON.stringify(payload))
  }
}

const userStore = new UserStore()

export default userStore